<template>
  <div class="nBank">
    <b-modal v-model="showModal" has-modal-card :can-cancel="true">
      <form>
        <div class="modal-card" style="width: 500px">
          <header class="modal-card-head">
            <p class="modal-card-title">PIX</p>
            <button type="button" class="delete" @click="showModal = false" />
          </header>
          <section class="modal-card-body">
            <img alt="nBank" src="../assets/pix.png" />

            <b-field>
              <b-select
                placeholder="Escolha o Banco"
                v-model="pix.banco"
                expanded
              >
                <option value="Nubank">Nubank</option>
                <option value="Neon">Neon</option>
                <option value="Next">Next</option>
                <option value="C6 Bank">C6 Bank</option>
              </b-select>
            </b-field>
            <small class="alert" v-if="alertBanco">* Escolha um banco.</small>

            <div class="field">
              <div class="control">
                <input
                  class="input is-primary"
                  id="chave-pix"
                  v-model="pix.chave"
                  type="text"
                  placeholder="Chave PIX"
                />
              </div>
            </div>
            <small class="alert" v-if="alertChave"
              >* Informe a Chave PIX.</small
            >

            <div class="field">
              <div class="control">
                <input
                  class="input is-primary"
                  id="valor"
                  v-model="pix.valor"
                  type="number"
                  placeholder="Valor"
                />
              </div>
            </div>
            <small class="alert" v-if="alertValor"
              >* Informe o valor para transferência.</small
            >

            <b-message
              type="is-danger"
              class="alert-danger"
              v-if="errorMessage"
            >
              {{ errorMessage }}
            </b-message>

            <b-message
              type="is-warning"
              class="alert-warning"
              v-if="alertMessage"
            >
              {{ alertMessage }}
            </b-message>
          </section>

          <footer class="modal-card-foot">
            <button class="button" type="button" @click="fechaPix">
              Cancelar
            </button>
            <button
              type="button"
              class="button is-primary has-text-white"
              @click="enviarPix()"
            >
              Enviar PIX
            </button>
          </footer>
        </div>
      </form>
    </b-modal>

    <div class="container mb-5" id="nav">
      <b-navbar>
        <template slot="brand">
          <b-navbar-item tag="router-link" :to="{ path: '/' }">
            <img alt="nBank" src="../assets/logo.svg" />
          </b-navbar-item>
        </template>
        <template slot="end">
          <b-navbar-item href="#">
            Olá Papito, seu saldo é R$ {{ saldo }}
          </b-navbar-item>
          <b-navbar-item tag="div">
            <div class="buttons">
              <a class="button is-primary" @click="deposita()"
                ><strong>Depositar</strong></a
              >
              <a class="button is-success" @click="showModal = true"
                ><strong>Fazer PIX</strong></a
              >
            </div>
          </b-navbar-item>
        </template>
      </b-navbar>
    </div>

    <div class="container">
      <b-carousel>
        <b-carousel-item v-for="(carousel, i) in carousels" :key="i">
          <section :class="`hero is-medium is-${carousel.color}`">
            <div class="hero-body has-text-centered">
              <h1 class="title">{{ carousel.text }}</h1>
            </div>
          </section>
        </b-carousel-item>
      </b-carousel>

      <div class="extrato mt-5">
        <section>
          <b-table
            :data="isEmpty ? [] : extrato"
            :bordered="isBordered"
            :striped="isStriped"
            :narrowed="isNarrowed"
            :hoverable="isHoverable"
            :loading="isLoading"
            :focusable="isFocusable"
            :mobile-cards="hasMobileCards"
          >
            <b-table-column field="id" label="Código" v-slot="props">
              {{ props.row.id }}
            </b-table-column>

            <b-table-column field="trx" label="Transação" v-slot="props">
              {{ props.row.trx }}
            </b-table-column>

            <b-table-column field="detalhes" label="Detalhes" v-slot="props">
              {{ props.row.detalhes }}
            </b-table-column>

            <b-table-column field="data" label="Data" centered v-slot="props">
              <span class="tag is-primary">
                {{ new Date(props.row.data).toLocaleDateString() }}
              </span>
            </b-table-column>

            <b-table-column label="Valor" numeric v-slot="props">
              <span :class="pegaIcone(props.row.tipo)">
                R$ {{ props.row.valor }}
                <b-icon pack="fas" :icon="pegaIcone(props.row.tipo)"> </b-icon>
              </span>
            </b-table-column>
          </b-table>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      showModal: false,
      errorMessage: "",
      alertMessage: "",
      alertBanco: false,
      alertChave: false,
      alertValor: false,
      valorDeposito: 0,
      pix: {
        banco: null,
        chave: "",
        valor: null,
      },
      saldo: 0,
      extrato: [
        {
          id: "299-559-4431",
          trx: "Compra no Cartão Int",
          detalhes: "Amazon.com.br",
          valor: -300.0,
          data: "2020/11/10 13:43:27",
          tipo: 1,
        },
        {
          id: "928-610-4186",
          trx: "Recebimento PIX",
          detalhes: "QAninja Academy pagamento de Salário",
          valor: 1000.0,
          data: "2020/11/05 13:43:27",
          tipo: 0,
        },
        {
          id: "955-756-8577",
          trx: "Compra no Cartão Nac",
          detalhes: "Dogão do Betão",
          valor: -200.0,
          data: "2020/10/30 13:43:27",
          tipo: 1,
        },
        {
          id: "808-953-4484",
          trx: "Recebimento PIX",
          detalhes: "NuBank - PIX Fernando Papito",
          valor: 500.0,
          data: "2020/10/20 13:43:27",
          tipo: 0,
        },
      ],
      carousels: [
        {
          text: "Reinvente a sua vida financeira",
          color: "primary",
        },
        {
          text: "Rende mais que a poupança.",
          color: "info",
        },
        {
          text: "Moderno, simples e gratuito.",
          color: "danger",
        },
        {
          text: "Porque tecnologia e design são melhores do que agências e papelada.",
          color: "success",
        },
        {
          text: "Para aprender Automação de Testes na prática!",
          color: "primary",
        },
      ],
      isEmpty: false,
      isBordered: false,
      isStriped: false,
      isNarrowed: false,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
    };
  },
  methods: {
    async deposita() {
      this.$swal
        .fire({
          title: "Crédito em conta",
          text: "Qual o valor para deposito em conta?",
          input: "number",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Enviar",
          cancelButtonText: "Cancelar",
          showLoaderOnConfirm: true,
          customClass: "modal-deposito",
          preConfirm: (valor) => {
            if (valor <= 0) {
              this.$swal.fire({
                icon: "error",
                title: "Oops...",
                text: "O valor do depósito deve ser maior que ZERO!",
              });
            }
            this.valorDeposito = valor;
          },
          allowOutsideClick: () => !Swal.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
            const trx = {
              id: this.$faker().phone.phoneNumberFormat(),
              trx: "Crédito em conta",
              detalhes: `Depósito online`,
              valor: +this.valorDeposito,
              data: new Date(),
              tipo: 0,
            };

            this.extrato.push(trx);
            this.calculaSaldo();
          }
        });
    },
    pegaIcone(tipo) {
      if (tipo === 0) return "plus-circle";
      if (tipo === 1) return "minus-circle";
      return null;
    },
    calculaSaldo() {
      this.saldo = 0;
      for (let i = 0; i < this.extrato.length; i += 1) {
        this.saldo += this.extrato[i].valor;
      }
    },
    async fechaPix() {
      this.showModal = false;
      this.alertBanco = false;
      this.alertChave = false;
      this.alertValor = false;
    },
    async enviarPix() {
      const { pix } = this;
      const valor = parseFloat(pix.valor);

      this.alertMessage = "";
      this.errorMessage = "";

      this.alertBanco = false;
      this.alertChave = false;
      this.alertValor = false;

      if (!pix.banco) this.alertBanco = true;
      if (!pix.chave) this.alertChave = true;
      if (!pix.valor) this.alertValor = true;

      if (pix.banco && pix.chave && pix.valor) {
        if (pix.valor <= 0) {
          this.alertMessage = "Oops. Transferir ZERO é osso hein...";
          return;
        } else if (this.saldo < pix.valor) {
          this.errorMessage = "Oops. Saldo insuficiente :(";
          return;
        }

        const trx = {
          id: this.$faker().phone.phoneNumberFormat(),
          trx: "Envio de PIX",
          detalhes: `${pix.banco} - PIX para ${pix.chave}`,
          valor: -valor,
          data: new Date(),
          tipo: 1,
        };

        this.extrato.push(trx);
        this.calculaSaldo();
        this.showModal = false;
        this.pix = {
          banco: null,
          chave: "",
          valor: null,
        };
      }
    },
  },
  mounted() {
    this.calculaSaldo();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.is-black {
  cursor: not-allowed;
  pointer-events: none;
}

.plus-circle {
  color: #48c774;
}

.alert {
  display: flex;
  color: #f14668;
  margin-top: -10px;
  margin-bottom: 5px;
  margin-left: 5px;
}

article[class*="message"] {
  font-size: 14px;
}

.minus-circle {
  color: #f14668;
}
</style>
