import Vue from 'vue';
import Buefy from 'buefy';
import App from './App.vue';
import router from './router';

import 'buefy/dist/buefy.css';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(Buefy);

window.axios = require('axios');

window.axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
window.axios.defaults.headers.post['Content-Type'] = 'application/json';
window.axios.defaults.headers.put['Content-Type'] = 'application/json';

window.axios.defaults.baseURL = 'http://localhost:3333';
// window.axios.defaults.baseURL = 'http://localhost:5000/api';

Vue.config.productionTip = false;

Vue.use(require('vue-faker'));
Vue.use(VueSweetalert2);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
